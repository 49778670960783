import React from 'react'
import { Link } from 'gatsby'
import styles from './Header.module.css'
import Navigation from '../Layout/Navigation'
import Content from '../Blocks/Content'

function Header({ children, ...props }) {
  const [visibleMenu, setVisibleMenu] = React.useState(false)

  return (
    <header role="banner">
      <div className={styles.headerBar}>
        <Content>
          <div className={styles.flex}>
            <Link className={styles.link} to="/">
              <img
                className={styles.logo}
                src="/casinoindex-logo.svg"
                alt="CasinoIndex logo"
              />
            </Link>
            <button
              className={
                visibleMenu ? styles.activeMenuButton : styles.menuButton
              }
              onClick={() => setVisibleMenu(!visibleMenu)}
              type="button"
              aria-label="Öppna och stäng meny"
            >
              <span className={styles.menuIcon}></span>
              {visibleMenu ? 'Stäng' : 'Meny'}
            </button>
          </div>
        </Content>
      </div>
      {children}
      <Navigation visible={visibleMenu} />
    </header>
  )
}

export default Header
