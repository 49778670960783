import React from 'react'
import Cookies from '../Blocks/Cookies'
import Footer from './Footer'

const Page = ({ children }) => {
  return (
    <React.Fragment>
      <main role="main">
        {children}
        <Cookies />
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default Page
