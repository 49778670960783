import React from 'react'
import styles from './AgeLimit.module.css'

function AgeLimit(props) {
  return (
    <div className={styles.age}>
      <div className={styles.content}>
        <img
          className={styles.img}
          src="/aldersgrans.svg"
          alt="Ikon för åldersgräns 18 år "
        />
        <p className={styles.text}>
          Spela ansvarsfullt. Spelproblem? Kontakta{' '}
          <a
            className={styles.link}
            rel="nofollow"
            href="https://www.stodlinjen.se/#!/"
          >
            Stödlinjen
          </a>
        </p>
      </div>
    </div>
  )
}

export default AgeLimit
