import React from 'react'
import { Link } from 'gatsby'
import styles from './NotFound.module.css'
import Page from '../Layout/Page'
import Header from '../Layout/Header'
import Content from '../Blocks/Content'

function NotFound(props) {
  return (
    <React.Fragment>
      <Header />
      <Page>
        <div className={styles.notFound}>
          <Content>
            <div className={styles.status}>
              <span className={styles.number}>4</span>
              <span className={styles.number}>0</span>
              <span className={styles.number}>4</span>
            </div>
            <section>
              <h1 className={styles.heading}>Sidan kunde inte hittas</h1>
              <p>
                Sidan du letar efter har flyttats, blivit borttagen, bytt namn
                eller aldrig existerat
              </p>
              <Link className={styles.linkButton} to="/">
                Gå till startsidan
              </Link>
            </section>
          </Content>
        </div>
      </Page>
    </React.Fragment>
  )
}
export default NotFound
