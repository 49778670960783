import React from 'react'
import { Link } from 'gatsby'
import styles from './Footer.module.css'
import AgeLimit from '../Blocks/AgeLimit'

function Footer(props) {
  return (
    <footer>
      <AgeLimit />
      <div className={styles.footer}>
        <div className={styles.footerGroup}>
          <h2 className={styles.listHeading}>Jämför Casinosidor</h2>
          <ul className={styles.list}>
            <li className={styles.item}>
              <Link className={styles.itemLink} to="/bonuskompassen">
                Bonuskompassen
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.itemLink} to="/casino-utan-registrering">
                Casino utan registrering
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.itemLink} to="/bonuskompassen"></Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerGroup}>
          <h2 className={styles.listHeading}>Information</h2>
          <ul className={styles.list}>
            <li className={styles.item}>
              <Link className={styles.itemLink} to="/om-oss">
                Om oss
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.itemLink} to="/spelproblem">
                Spelproblem
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.itemLink} to="/villkor-och-policyer">
                Villkor och Policyer
              </Link>
            </li>
            <li className={styles.item}>
              <a className={styles.itemLink} href="/sitemap.xml">
                Webbkarta
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.subfooter}>
        © {new Date().getFullYear()} CasinoIndex – Jämför svenska casinosidor på
        nätet
      </div>
    </footer>
  )
}

export default Footer
