import React from 'react'
import styles from './Content.module.css'

function Content({ children, ...props }) {
  return (
    <div {...props} className={styles.content}>
      {children}
    </div>
  )
}

export default Content
