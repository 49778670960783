import React from 'react'
import { Link } from 'gatsby'
import styles from './Navigation.module.css'

function Navigation({ visible, ...props }) {
  return (
    <nav role="navigation" className={visible ? styles.activeNav : styles.nav}>
      <div className={styles.content}>
        <Link className={styles.navLink} to="/bonuskompassen">
          Bonuskompassen
        </Link>
        <Link className={styles.navLink} to="/casino-utan-registrering">
          Casino utan registrering
        </Link>
      </div>
    </nav>
  )
}

export default Navigation
