import React from 'react'
import SEO from '../components/Blocks/SEO'
import NotFound from '../components/Pages/NotFound'

const NotFoundPage = () => (
  <React.Fragment>
    <SEO
      title="Sidan kunde inte hittas"
      description="Sidan du letar efter har flyttats, blivit borttagen, bytt namn eller aldrig existerat"
    />
    <NotFound />
  </React.Fragment>
)

export default NotFoundPage
