import React from 'react'
import { Link } from 'gatsby'
import styles from './Cookies.module.css'

function Cookies(props) {
  const [useCookies, setUseCookies] = React.useState(false)
  React.useEffect(acceptsCookies, [])

  function acceptCookies() {
    let date = new Date()
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000)

    document.cookie = `cookies_accepted=true; expires=${date.toGMTString()}`
    setUseCookies(true)
  }

  function acceptsCookies() {
    if (document.cookie.indexOf('cookies_accepted=') >= 0) {
      setUseCookies(true)
    }
  }

  return (
    <React.Fragment>
      {!useCookies ? (
        <div className={styles.cookies}>
          <div className={styles.cookieContent}>
            <img className={styles.cookieImg} src="/kaka.svg" alt="kaka ikon" />
            <div className={styles.cookieText}>
              <p className={styles.text}>
                Vi använder kakor (cookies) för att förbättra din upplevelse.
                Genom att fortsätta accepterar du användandet av kakor.
              </p>
              <div className={styles.actions}>
                <button
                  className={styles.button}
                  onClick={() => acceptCookies(true)}
                >
                  Acceptera
                </button>
                <Link className={styles.link} to="/villkor-och-policyer">
                  Läs mer
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default Cookies
